import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

const MichiganEstateRecovery = () => (
  <Layout>
    <SEO title="Michigan Estate Recovery" />
    <section className="section">
      <div className="container">
        <div className="columns">
          <div className="column is-full is-8-widescreen">
            <h1 className="title is-size-1">Michigan Estate Recovery</h1>
            <p>
              Michigan&#8217;s &#8220;estate recovery&#8221; law was put into
              effect in 2011 with retroactive effect to 2007. &#8220;Estate
              recovery&#8221; is a law that allows the State of Michigan to make
              claims against assets of seniors who receive Medicaid nursing home
              benefits after September 30, 2007, the date the law originally
              passed. The intent of the law is to allow for the government to
              recoup money spent on Medicaid. Private pay rates at nursing homes
              currently average around $9,000 per month, so many seniors wind up
              with Medicaid helping foot the bill. With Medicaid, the most a
              person must pay is his or her monthly income, typically Social
              Security and possibly a pension.
            </p>
            <h2 className="title is-2">What does the new rule say?</h2>
            <p>
              The rule explains the law and provides procedural guidelines for
              the state to make claims.
            </p>
            <h2 className="title is-2">What assets are at stake?</h2>
            <p>
              Currently, probate assets of the Medicaid recipient are subject to
              claim.
            </p>
            <h2 className="title is-2">
              Is the best approach to avoid probate?
            </h2>
            <h2 className="title is-2">What about a trust?</h2>
            <p>
              Medicaid recipients may be able to bypass being affected by this
              rule by ensuring that they do not have a probate estate. As most
              Medicaid recipients have no more than $2,000 in liquid assets, the
              house is typically the primary remaining asset. While a Medicaid
              recipient may not have their house in a revocable living trust,
              the recipient may utilize a &#8220;Lady-Bird Deed&#8221; (a.k.a.
              an &#8220;enhanced life estate deed&#8221; or &#8220;transfer on
              death deed&#8221;) to avoid probate.
            </p>
            <h2 className="title is-2">Proposed Legislation</h2>
            <p>
              The state has had legislation pending that would make the estate
              recovery law more strict, using liens and other mechanisms to make
              claims against even more assets.
            </p>
            <h2 className="title is-2">History of estate recovery</h2>
            <p>
              In 1993, the federal government passed a law requiring all states
              to adopt an estate recovery (or similar) program, leaving it in
              each state&#8217;s hands to design their own program. Although
              Michigan legislators did finally comply with the federal law, they
              were consistently reluctant to support such a program. Their
              reasons for opposing estate recovery were many.
            </p>
            <p>
              It was unpopular with constituents. People do not want the state
              to &#8220;take grandma&#8217;s home&#8221;. It targets only senior
              citizens who receive Medicaid. Seniors are a small fraction of the
              Medicaid budget, but easier targets for recovery than low-income
              beneficiaries.
            </p>
            <p>
              Other states&#8217; estate recovery programs are unsuccessful.
              Administrative expenses usually reduce actual recovery to less
              than twenty cents on the dollar.
            </p>
            <p>
              Many states lose property tax revenue because families are not
              willing to pay taxes on &#8211; or pay for the upkeep &#8211; of
              homes that the state will make a claim against when their parents
              pass away. In Michigan, the real estate market is failing. It will
              not behoove the state to own part or all of former Medicaid
              recipients&#8217; homes. In many cases, it is unlikely that
              probate beneficiaries will be willing or able to pay off the state
              for their claim against the house, so the state may wind up owning
              the real estate &#8211; often non-saleable.
            </p>
            <p>
              Some seniors will avoid seeking necessary care if they realize
              that the state will have a claim against their home.
            </p>
            <p>
              All of these changes make it even more clear that families should
              prepare ahead of time for the possibility of long-term care and
              how that may affect their estates.
            </p>
          </div>
        </div>
      </div>
    </section>
  </Layout>
)

export default MichiganEstateRecovery
